import { CountUp } from 'countup.js';
import DetectInViewport from '../utils/DetectInViewport';

/**
 *
 * @param {*} element The metric element
 */

const Metric = element => {
  const metricNum = element.querySelector('.number');
  let number = metricNum.innerHTML;
  let metricTriggered = false;

  //* Get characters in front of the number value
  let precedingCharacters = number.match(/^[^\d]+|$/);

  if (precedingCharacters) {
    [precedingCharacters] = precedingCharacters;
  }

  //* Get characters after the number value
  let followingCharacters = number.match(/[^\d]+(\.*)$/);

  if (followingCharacters) {
    [followingCharacters] = followingCharacters;
  }

  //* Store the numbers from the string
  number = number.replace(/[^0-9.]/g, '');

  const counter = new CountUp(metricNum, number, {
    //* Pass in the preceding and trailing characters as options
    prefix: precedingCharacters || '',
    suffix: followingCharacters || '',
    decimalPlaces: number % 1 !== 0 ? 1 : 0,
    duration: 4,
  });

  if (DetectInViewport(element)) {
    counter.start();
    metricTriggered = true;
  }

  window.addEventListener('scroll', () => {
    if (DetectInViewport(element) && !metricTriggered) {
      counter.start();
      metricTriggered = true;
    }
  });
};

export default Metric;
