import SmoothScroll from 'smooth-scroll';

const ConfigureSmoothScroll = header => {
  const body = document.querySelector('body');
  const headerInfo = header.getBoundingClientRect();
  const headerHeight = headerInfo.height;

  return new SmoothScroll('a[href*="#"]', {
    header: body.classList.contains('fixed-header-enabled')
      ? `.site-header`
      : ``,
    offset(anchor, toggle) {
      if (
        !anchor.parentElement.parentElement.classList.contains('scroll-up') &&
        header.classList.contains('fixed-up')
      ) {
        return -headerHeight;
      }

      return 0;
    },
  });
};

export default ConfigureSmoothScroll;
