const DetectClickOutsideContainer = (containers) => { 
   containers.forEach((c) => {
    c.addEventListener('click', (e) => {
        const childElement = c.firstElementChild.classList.toString();
        if(e.target.closest(`.${childElement}`)) return
        c.classList.remove('open');
    })
   })
}

export default DetectClickOutsideContainer;