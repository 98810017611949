/**
 *
 * @param {*} element The accordion element
 */

const Accordion = element => {
  const title = element.querySelector('.title');

  title.addEventListener('click', () => {
    title.parentElement.classList.toggle('open');
  });
};

export default Accordion;
