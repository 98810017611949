import axios from 'axios';

const FetchEntryEndpoint = async endpoint => {
  const dataArr = [];

  await axios
    .get(`/!/Fetch/entry/${endpoint}`)
    .then(res => {
      const endpointData = res.data.data;

      dataArr.push(endpointData);
    })
    .catch(err => {
      console.log(`Error: ${err}`);
    });

  return dataArr;
};

export default FetchEntryEndpoint;
