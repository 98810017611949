import Swiper from 'swiper';

const Carousel = (element, slides = 3) =>
  new Swiper(element, {
    slidesPerView: 1,
    spaceBetween: 60,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
    },
    breakpoints: {
      600: {
        slidesPerView: Math.abs(slides) - 1 > 1 ? Math.abs(slides) - 1 : 2,
      },
      1024: {
        slidesPerView: Math.abs(slides),
        spaceBetween: 80,
      },
    },
  });

export default Carousel;
